<template>
    <div class="flat-details apartments page">  
        <!--------------------- FLAT DETAILS--------------->
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12" class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
                    <router-link v-if="currentBuilding === 'apartments'" class="go-back" :to="{name: 'flats', params:{building_name: currentBuilding, level:currentLevel}}">
                        <img src="@/assets/images/icons/ic-arrow-right-black-big.svg" />
                        {{tr('flat_details_go_back_flats')}}
                    </router-link>
                    <router-link v-if="currentBuilding === 'villa'" class="go-back" :to="{name: 'levels', params:{building_name: currentBuilding}}">
                        <img src="@/assets/images/icons/ic-arrow-right-black-big.svg" />                        
                        {{tr('flat_details_go_back_floors')}}
                    </router-link>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="6" xl="7">                    
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')" v-html="tr('choose_building_title')"></h2>                            
                </b-col>
                <b-col cols="12" md="6" xl="5" class="d-none d-md-block">
                    <div class="border-wrap">
                        <router-link :to="{name: 'contact'}" class="btn btn--black btn--arrow animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">
                            <span>{{tr('btn_ask_offer')}}</span>
                        </router-link>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-container fluid="xl" v-for="(flatFloor, fIndex) in flat.flatsFloor" :key="fIndex">
            <b-row>
                <b-col cols="12">
                    <div class="flat__name animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">{{flatFloor.name[selectedLanguage]}}</div>                   
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="6" xl="7">                    
                    <img @click="galleryIndex = fIndex" :src="baseUrl + flatFloor.flatFloorImgSrc[selectedLanguage]" :alt="flatFloor.name" class="w-100 flat__img animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"/>
                    <button type="button" class="btn  btn--zoom-black d-none d-md-block" @click="galleryIndex = fIndex"></button>
                    <!-- MOBILE BTNS -->
                    <router-link :to="{name: 'contact'}" class="btn btn--black btn--arrow d-md-none animateScroll ask-offer" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
                        <span>{{tr('btn_ask_offer')}}</span>
                    </router-link>                   
                </b-col>
                <b-col cols="12" md="6" xl="5">
                    <div class="border-wrap">
                        <table cellpadding="0" cellspacing="0" class="flat__details" v-if="flatFloor.details">                        
                            <tr v-for="(place, pIndex) in flatFloor.details" :key="pIndex" class="flat__place animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', `delay-${pIndex+1}`)">
                                <td class="place__num">{{place.placeNum}}</td>
                                <td class="place__name" v-html="place.placeName[selectedLanguage]"></td>
                                <td class="place__size">{{place.placeSize}}</td>
                            </tr>
                        </table>
                    </div>
                </b-col>
            </b-row>
        </b-container> 
        <vue-gallery-slideshow :images="alaprajzok" :index="galleryIndex" @close="galleryIndex = null"></vue-gallery-slideshow> 
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">  
                    <div class="btn-wrap" v-if="flat.pdfSrc">
                        <button type="button" class="btn btn--line btn--print animateScroll" @click="Print(baseUrl + flat.pdfSrc)" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-from-left')">{{tr('btn_print')}}</button>                        
                        <a target="_blank" download :href="baseUrl + flat.pdfSrc" type="button" class="btn btn--line btn--download animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-from-left', 'delay-1')">{{tr('btn_letoltes')}}</a>
                    </div>                        
                </b-col>                
            </b-row>
        </b-container>   
        <!--------------------- TAKE A LOOK INSIDE --------------->       
        <TakeLookInside  :flatslides="flat.detailsPictures" :flatslidesBig="flat.detailsPicturesBig" :showTitle="true" />
        <!-------------------- PARTNERS -------------------------->
        <Partners />    
        <div class="bgr-img animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
            <img src="@/assets/images/bgr/luminor-panorama-03-mobile.jpg" />
        </div>    
    </div>
</template>

<script>
import TakeLookInside from '@/components/pages/TakeLookInside.vue'
import Partners from '@/components/Partners.vue'
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
    data(){
        return{
            flat:null,    
            galleryIndex : null       
        }
    },
    components: {        
        TakeLookInside,
        Partners,
        VueGallerySlideshow
    },
    computed:{
        alaprajzok(){
            var ret = [];
            for (var i = 0; i< this.flat.flatsFloor.length; i++)
            {
                ret.push(this.baseUrl + this.flat.flatsFloor[i].flatFloorImgSrc[this.selectedLanguage]);
            }
            return ret;
        },
        apartments(){
            return this.$store.state.apartments
        },
        currentBuilding(){
            return this.$route.params.building_name            
        },
        currentLevel(){
            return this.$route.params.level            
        },
        currentFlat(){
            return this.$route.params.flat_slug
        }
    },
    methods:{
        getLevels(){            
            let _this = this

            let currentBuildingLevel = this.apartments.find( item => item.slug === _this.currentBuilding).levels

            let flats = currentBuildingLevel.find( item => item.slug === _this.currentLevel).flats

            _this.flat = flats.find( item => item.slug === _this.currentFlat)
        },
        Print(documentSrc){            
            
            var iframe = document.createElement('iframe')
            iframe.style.display = "none"            
            iframe.setAttribute('id','printPdfIframe')
            iframe.src = documentSrc
            document.body.appendChild(iframe)

            iframe.contentWindow.focus();
            iframe.contentWindow.print();
        }
    },
    created(){
        this.getLevels()
    },
    destroyed(){
        let iframe = document.getElementById('printPdfIframe')

        if (iframe){
            iframe.remove()
        }
        
    }
    
}
</script>