var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"partners"},[_c('b-container',{attrs:{"fluid":"xl"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'fade-up'); }),expression:"(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"}],staticClass:"animateScroll"},[_vm._v(" "+_vm._s(_vm.tr('partners_title'))+" ")]),_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'fade-up', 'delay-1'); }),expression:"(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')"}],staticClass:"partners__carousel-wrap animateScroll"},[_c('OwlCarousel',{attrs:{"items":5,"margin":40,"dots":true,"loop":true,"nav":false,"responsive":{ 
                                0:{
                                    items:2,														
                                    margin: 20,
                                    mouseDrag: true,                                 
                                },      													
                                768:{items:4, mouseDrag:true, margin: 30},
                                992:{items:5, mouseDrag:true, margin:20}
                            }}},_vm._l((_vm.slides),function(slide,sindex){return _c('div',{key:sindex},[(slide.href)?_c('a',{attrs:{"href":slide.href,"target":"_blank"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":'/pictures/partners/' + slide.src}})]):_c('img',{staticClass:"img-fluid",attrs:{"src":'/pictures/partners/' + slide.src}})])}),0)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }