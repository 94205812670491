<template>
    <section class="partners">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
                        {{tr('partners_title')}}
                    </h2>                  
                    <div class="partners__carousel-wrap animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">
                        <OwlCarousel  :items="5" :margin="40" :dots="true"  :loop="true" :nav="false"
                                :responsive="{ 
                                    0:{
                                        items:2,														
                                        margin: 20,
                                        mouseDrag: true,                                 
                                    },      													
                                    768:{items:4, mouseDrag:true, margin: 30},
                                    992:{items:5, mouseDrag:true, margin:20}
                                }">
                                <div class="" 
                                    v-for="(slide, sindex) in slides" :key="sindex"                                 
                                >                       
                                    <a :href="slide.href" target="_blank" v-if="slide.href">
                                        <img :src="'/pictures/partners/' + slide.src" class="img-fluid"  />                            
                                    </a>
                                    <img v-else :src="'/pictures/partners/' + slide.src" class="img-fluid"  />                            
                                </div> 
                        </OwlCarousel>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import OwlCarousel from 'vue-owl-carousel'

export default {    
    components:{
        OwlCarousel
    },
    data(){
        return{            
            slides:[                
                // {
                //     src: 'logo-01.jpg',
                //     href: ''
                // },
                {
                    src: 'logo-02.jpg',
                    href:'https://www.minotti.com/en'
                },
                {
                    src: 'logo-03.jpg',
                    href: 'https://www.antoniolupi.it/en/'
                },
                {
                    src: 'logo-06.jpg',
                    href: 'https://www.arclinea.com/eng/'
                },
                /*{
                    src: 'logo-04.jpg',
                    href: 'https://www.boffi.com/en-us/'
                },*/
                {
                    src: 'logo-05.jpg',
                    href: 'https://www.axor-design.com/int/'
                },
                
                {
                    src: 'logo-07.jpg',
                    href: 'https://www.porro.com/en'
                },
                {
                    src: 'logo-08.jpg',
                    href: 'https://www.rimadesio.it/en'
                },
                {
                    src: 'logo-09.jpg',
                    href: 'https://flos.com/'
                },
                {
                    src: 'logo-10.jpg',
                    href: 'https://www.miele.com/en/com/index.htm'
                },
                {
                    src: 'logo-11.jpg',
                    href: 'https://www.bebitalia.com/en/'
                },
                { 
                    src: 'logo-12.jpg',
                    href: 'https://www.knoll.com/'
                },
                {
                    src: 'logo-13.jpg',
                    href: 'https://www.maxalto.com/en/'
                },
                {
                    src: 'logo-14.jpg',
                    href: 'https://www.olivari.it/en'
                }
            ],                    
        }
    }
}
</script>